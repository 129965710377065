<template>
  <div class="column is-12 is-pulled-left tabela-container">
    <div class="table-scroll">
      <table id="tabelaBoletos" class="table is-fullwidth is-bordered is-striped main-table">
        <thead>
          <tr class="cabecalhoTabela is-unselectable has-text-centered">
            <th class="is-unselectable has-text-centered ItenTabela">Origem</th>
            <th class="is-unselectable has-text-centered ItenTabela">CRM</th>
            <th class="is-unselectable has-text-centered ItenTabela">Status</th>
            <th class="is-unselectable has-text-centered ItenTabela">Data inclusão</th>
            <th class="is-unselectable has-text-centered ItenTabela">Valor pago</th>
            <th class="is-unselectable has-text-centered ItenTabela">Valor tarifa</th>
            <th class="is-unselectable has-text-centered ItenTabela">Data pagamento</th>
            <th class="is-unselectable has-text-centered ItenTabela">Fintech</th>
            <th class="is-unselectable has-text-centered ItenTabela">Data geração</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(boleto, i) in dadosGridBoleto" :key="i">
            <td v-for="(campos, i) in boleto" :key="i"
            class="has-text-centered">{{ campos }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="dadosGridBoleto.length <= 0">
        <h1 class="box is-unselectable ajuste-resolucao">Nenhum dado a ser exibido.</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'tabela',
  props: {
    dadosGridBoleto: Array,
  },
  methods: {
    exportTableToExcel(tableID, filename = ''){
        let downloadLink;
        let dataType = 'application/vnd.ms-excel;charset=UTF-8';
        //let tableSelect = document.getElementById(tableID);
        let tableSelect = document.querySelector('atena-relatorio-boleto').shadowRoot.getElementById(tableID);
        let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
        
        // Specify file name
        filename = filename?filename+'.xls':'excel_data.xls';
        
        // Create download link element
        downloadLink = document.createElement("a");
        
        document.body.appendChild(downloadLink);
        
        if(navigator.msSaveOrOpenBlob){
            let blob = new Blob(['\ufeff', tableHTML], {
                type: dataType
            });
            navigator.msSaveOrOpenBlob( blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        
            // Setting the file name
            downloadLink.download = filename;
            
            //triggering the function
            downloadLink.click();
        }
    }
  }
}
</script>