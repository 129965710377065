export const origem = [
    {codigo: "", descricao: 'Todos'},
    {codigo: -1, descricao: 'Não Informado'},
    {codigo: 0, descricao: 'ErpForMe'},
    {codigo: 1, descricao: 'WShop'},
    {codigo: 2, descricao: 'Bimer'},
    {codigo: 3, descricao: 'Financeiro Pack'}
]

export const status = [
    {codigo: "",descricao: 'Todos'},
    {codigo: -1,descricao: 'Dados Incompletos'},
    {codigo: 0 ,descricao: 'Em aberto'},
    {codigo: 1 ,descricao: 'Pago'},
    {codigo: 2 ,descricao: 'Vencido'},
    {codigo: 3 ,descricao: 'Devolvido'},
    {codigo: 4 ,descricao: 'Contestado'},
    {codigo: 5 ,descricao: 'Cancelado'},
    {codigo: 6 ,descricao: 'Pago manualmente'}
]

export const fintech = [
    {codigo: 0 ,descricao: 'Gerencianet'}
]

export function getDescricao(enumerador, codigo) {
    for (let index = 0; index < enumerador.length; index++) {
        const element = enumerador[index];
        if (element.codigo === codigo) {
            return element.descricao
        }
    }
    return null
}