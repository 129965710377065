import axios from 'axios'
import config from 'config'

export const autenticacaoServico = {
    autenticar,
    gerarToken
}
function autenticar(commit,payload) {
    let dadosModal = {
        exibirModal: true,
        textoModal:'Inicializando componente'
    }
    commit("exibirOcultarModalLoading", dadosModal)
    return axios(`${config.apiBoleto}/autenticacao/${payload}/gerarchaves`)
    .then(function(response) {
        gerarToken(commit,response.data)
        return response
    })
    .catch(function (error) {
        commit("exibirOcultarModalLoading", false)
        return error
    })
}

function gerarToken(commit,payload) {
    const requestOptions = {
        method: 'post',
        data: payload
    }
    return axios(`${config.apiBoleto}/autenticacao`,requestOptions)
    .then(function(response) {
        commit("armazenarToken", response.data.token)
        return response
    })
    .catch(function (error) {
        return error
    })
    .finally(() => {
        commit("exibirOcultarModalLoading", false)
    })
}