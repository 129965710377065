import { utils } from '@/helpers/utils'
import { getDescricao, origem, status, fintech } from '@/helpers/enums.js'

export default class tabelaParser {
    constructor() {}
    parseTo = function (tabela) {
        let tabelaDTO = {}
        
        //tabelaDTO.id = tabela.id
        tabelaDTO.origem = getDescricao(origem,tabela.origem)
        tabelaDTO.crm = tabela.crm
        tabelaDTO.status = getDescricao(status,tabela.status)
        tabelaDTO.dataHoraInclusao = utils.dataHoraFormatada(tabela.dataHoraInclusao)
        tabelaDTO.valorPago =  utils.valorFormatado(tabela.valorPago)
        tabelaDTO.valorTarifa = utils.valorFormatado(tabela.valorTarifa)
        tabelaDTO.dataHoraPagamento = utils.dataHoraFormatada(tabela.dataHoraPagamento)
        tabelaDTO.fintech = getDescricao(fintech, tabela.fintech)
        tabelaDTO.dtGeracao = utils.dataHoraFormatada(tabela.dtGeracao)
        
        return tabelaDTO
    }

}