export const utils = {
    dataHoraFormatada,
    valorFormatado,
    somenteNumeros,
}

function dataHoraFormatada (dataHora) {

    if(dataHora) {
        let data = new Date(dataHora)
        let dia  = data.getDate().toString()
        let diaF = (dia.length === 1) ? '0' + dia : dia
        let mes  = (data.getMonth() + 1).toString()
        let mesF = (mes.length === 1) ? '0' + mes : mes
        let anoF = data.getFullYear()
        let hora = data.getHours().toString()
        let horaF = (hora.length === 1) ? '0' + hora : hora
        let minutos = data.getMinutes().toString()
        let minutosF = (minutos.length === 1) ? '0' + minutos : minutos
    
        return diaF + "/" + mesF + "/" + anoF + " " + horaF + ":" + minutosF
    }else{
        return ""
    }
}

function valorFormatado (valor) {
    let decimalCount = 2

    if(valor) {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
        const negativeSign = valor < 0 ? "-" : "";
    
        let i = parseInt(valor = Math.abs(Number(valor) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
    
        return negativeSign + (j ? i.substr(0, j) + "." : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + ".") + (decimalCount ? "," + Math.abs(valor - i).toFixed(decimalCount).slice(2) : "");
    }else{
        return ""
    }
}

function somenteNumeros(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    } else {
      return true;
    }
}