import axios from 'axios'
import config from 'config'

export const boletoServico = {
    retornaBoleto
}
function retornaBoleto(commit, dispatch, payload) {
    let dadosModal = { exibirModal: true,
                        textoModal:'Realizando Consulta' }
    commit("exibirOcultarModalLoading", dadosModal)
    const requestOptions = {
        params:{
            ...payload.dadosRequisicao
        },
        headers: {
            authorization: payload.token
        }
    }
    return axios(`${config.apiBoleto}/boletos/retornarpororigem`,requestOptions)
    .then(function(response) {
        dispatch("listaBoletosParser", response.data)
        return response
    })
    .catch(function (error) {
        return error
    })
    .finally(() => {
        commit("exibirOcultarModalLoading", false)
    })
}