<template>
  <div id="app">
    <div class="column is-12 is-pulled-left is-marginless is-paddingless has-text-centered">
      <span class="is-pulled-left has-margin-left-30 is-size-4">Consulta de boletos emitidos</span>
    </div>
    <hr class="column is-12 is-pulled-left is-marginless is-paddingless">
    <div class="column is-12 is-pulled-left has-padding-top-5 has-padding-bottom-5">
      <filtro
        @obterBoletosAcionado ="atualizarParametros($event)"
      ></filtro> <!-- Import do componente Filtro -->
      <div class="column is-5 is-pulled-left divComponentes">
        <div class="column is-2 is-pulled-left is-unselectable">
          <button class="button is-small btnExportaExcel" @click="exportaExcel" title="Exportar para Excel" :class="{desabilitado: !Object.values(listaFiltrada).length}">
            Exportar
            </button>
        </div>
        <div class="column is-5 is-pulled-left is-unselectable has-padding-right-5">
          <ordenar 
            ref="componenteOrdenacao"
            :dadosGridBoleto="listaFiltrada"
            @gridOrdenada="listaFiltrada = $event"
            :class="{desabilitado: !Object.values(listaFiltrada).length}"
          ></ordenar> <!-- Import do componente Ordenar -->
        </div>
        <div class="column is-5 is-pulled-right has-padding-left-5"  :class="{desabilitado: !Object.values(listaFiltrada).length}">
            <i class="fa fa-search is-pulled-left has-padding-top-10 has-padding-left-5 has-padding-right-5" aria-hidden="true"></i>
            <input v-model="pesquisa" 
            @keyup="realizarFiltro(pesquisa)"
            @keyup.enter="executarFiltro(pesquisa)"
            type="text" 
            class="column is-10 input is-small is-pulled-left has-padding-right-5" 
            title="Pesquisar na grid." 
            placeholder="Digite para pesquisar na grid.">
        </div>
      </div>
    </div>
    <grid-boleto ref="gridListaBoletos" :dadosGridBoleto='listaFiltrada'></grid-boleto> <!-- Import do componente Tabela -->
    <div class="column is-12 is-pulled-left is-pulled-left is-marginless is-paddingless has-margin-left-25">
        <div class="column is-2 is-pulled-left has-background-white-ter">
          <span class="has-text-weight-bold">Total de registros:</span>
          <span class="valorTotalizador has-padding-left-10">{{ Object.values(listaFiltrada).length }}</span>
        </div>
        <div class="column is-2 is-pulled-left has-background-white-ter">
          <span class="has-text-weight-bold">Total pago:</span>
          <span class="valorTotalizador has-padding-left-10">{{ valorFormatado(valorTotalPago) }}</span>
        </div>
        <div class="column is-2 is-pulled-left has-background-white-ter">
          <span class="has-text-weight-bold">Total tarifa:</span>
          <span class="valorTotalizador has-padding-left-10">{{ valorFormatado(valorTotalTarifa) }}</span>
        </div>
    </div>
    <div class="modal" :class="{'is-active': dadosModal.exibirModal}">
      <div class="modal-background"></div>
      <div class="modal-card modal-load">
        <div class="field">
          <div class="control modal-load">
            <div class="loader-wrapper is-active">
            <span class="is-size-3 column is-5">{{dadosModal.textoModal}}</span>
              <div class="loader is-loading has-margin-right-20"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from './store'
import { utils } from '@/helpers/utils'
import GridBoleto from '../src/components/tabela.vue'
import Ordenar from '../src/components/ordenar.vue'
import Filtro from '../src/components/filtro.vue'

export default {
  Store,
  components: {
    GridBoleto,
    Ordenar,
    Filtro
  },
  name: "atena-relatorio-boleto",
  data(){
    return {
      pesquisa: '',
      filtroPesquisa: ''
    }
  },
  computed: {
    listaComParse() {
      return Store.state.listaBoletosParse ? Store.state.listaBoletosParse : []
    },
    listaFiltrada: {
      get() {
        return Store.state.listaFiltrada
      },
      set(lista) {
        Store.commit("preencherListaFiltrada", lista)
      }
    },
    valorTotalPago() {
      let totalPago = 0
      for (let index = 0; index < this.listaFiltrada.length; index++) {
        const element = this.listaFiltrada[index];
        !element.valorPago ? "" : totalPago = totalPago + parseInt(element.valorPago)
      }
      return totalPago
    },
    valorTotalTarifa() {
      let totalTarifa = 0
      for (let index = 0; index < this.listaFiltrada.length; index++) {
        const element = this.listaFiltrada[index];
        !element.valorTarifa ? "" : totalTarifa = totalTarifa + parseInt(element.valorTarifa)
      }
      return totalTarifa
    },
    retornoConsulta () {
      return Store.state.listaBoletos
    },
    dadosModal () {
      return Store.state.dadosModal
    }
  },
  methods: {
    exportaExcel() {
      this.$refs.gridListaBoletos.exportTableToExcel('tabelaBoletos')
    },
    atualizarParametros() {
       this.pesquisa = ''
       this.$refs.componenteOrdenacao.limparOrdenacao()
    },
    realizarFiltro(txtPesquisa) {
      clearInterval(this.filtroPesquisa)
      this.filtroPesquisa = setTimeout(() => {
        this.executarFiltro(txtPesquisa)
      }, 1500);
    },
    executarFiltro(txtPesquisa) {
      clearInterval(this.filtroPesquisa)
      this.listaFiltrada = this.listaComParse.filter(linha => {
        return Object.keys(linha).find(key => {
          if(!(linha[key])) {
            return false
          }
          return !!(linha[key]).toString().toUpperCase().includes(txtPesquisa.toUpperCase());
        });
      });
    },
    valorFormatado(valor) {
      return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    fecharModal() {
      this.exibirModal = false
    },
    somenteNumeros(evt) {
      utils.somenteNumeros(evt)
    }
  },
  mounted() {
    setTimeout(() =>{ 
      Store.dispatch("autenticar", '900000')
    }, 2000);
  }
}
</script>

<style lang="scss">
@import "./comum/styles/main.scss";
@import 'bulma/bulma.sass';
@import 'bulma-helpers/bulma-helpers.sass';
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>
