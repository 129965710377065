import { render, staticRenderFns } from "./ordenar.vue?vue&type=template&id=0bb38029&"
import script from "./ordenar.vue?vue&type=script&lang=js&"
export * from "./ordenar.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ordenar.vue?vue&type=style&index=0&id=0bb38029&prod&style=scss&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports