<template>
  <ValidationObserver tag="form" v-slot="{ handleSubmit  }">
        <form class="column is-7 is-pulled-left is-marginless is-paddingless" @submit.prevent="handleSubmit(obterBoletos)">
          <div class="column is-5 is-pulled-left is-marginless is-paddingless">
            <ValidationProvider class="column is-6 is-pulled-left has-padding-top-5" rules="required" name="dataInicial">
              <div slot-scope="{ errors }" class="mensagem">
                <span>* Data inclusão inicial:</span>
                <input class="input is-small"
                  v-model="dtInicio"
                type="date"/>
                <span class="is-size-7 has-text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <ValidationProvider class="column is-6 is-pulled-left has-padding-top-5" rules="required|validarData:@dataInicial">
              <div slot-scope="{ errors }" class="mensagem">
                <span>* Data inclusão final:</span>
                <input class="input is-small"
                  v-model="dtFim"
                  type="date"/>
                <span class="is-size-7 has-text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider >
          </div>
          <div class="column is-2 is-pulled-left is-unselectable alinhamentoResolucao" rules="required">
              <span>Origem:</span>
              <select v-model="parametros.origem"
                class="input is-small">   
                <option v-for="origem in origemEnum" 
                        :key="origem.descricao"
                        :value="origem.codigo"
                        :selected="origem" >
                        {{origem.descricao}}
                </option>
              </select>
          </div>
          <div class="column is-3 is-pulled-left is-unselectable alinhamentoResolucao" rules="required">
            <span>Status:</span>
            <select v-model="parametros.status"
              class="input is-small">   
              <option v-for="status in statusEnum" 
                      :key="status.descricao"
                      :value="status.codigo"
                      :selected="status" >
                      {{status.descricao}}
              </option>
            </select>
          </div>
          <div class="column is-2 is-pulled-left">
            <button class="button is-small btnConsultar" type="submit">Consultar</button>
          </div>
        </form>
      </ValidationObserver>
</template>

<script>
import Store from '../store'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { origem, status} from '@/helpers/enums.js'

extend('required', {
  ...required,
  message: 'Campo necessário.'
});

extend('validarData', {
  params: ['target'],
  validate(value, { target }) {
    return value >= target;
  },
  message: 'Data menor que a início.'
});

export default {
    Store,
    components: {
    ValidationObserver,
    ValidationProvider
    },
    computed: {
        origemEnum() {
            return origem
        },
        statusEnum() {
            return status
        },
        obterToken () {
            return Store.state.token
        }
    },
    name: 'filtro',
    data(){
        return {
        parametros: {
            origem: '',
            status: '',
        },
        dtInicio: '',
        dtFim: ''
        }
    },
    methods: {
        obterBoletos() {
            const requisicao = {
                dadosRequisicao: {
                ...this.parametros,
                dtInicio: this.dtInicio,
                dtFim: this.dtFim
                },
                token: this.obterToken
            }
            this.$emit('obterBoletosAcionado')
            Object.keys(requisicao.dadosRequisicao).forEach(key => requisicao.dadosRequisicao[key] === "" ? delete requisicao.dadosRequisicao[key] : {})
            Store.dispatch("retornarBoleto", requisicao)
        },
    }
}
</script>