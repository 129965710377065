<template>
    <div class="column is-12 is-pulled-left is-marginless is-paddingless">
        <div class="column is-4 is-pulled-left dropdown is-hoverable is-pulled-left is-paddingless">
            <div class="dropdown-trigger">
            <button class="button is-small" aria-haspopup="true" aria-controls="dropdown-menu4">
                <span>Ordenar </span>
                <span class="icon is-small">
                    <i  class="fas fa-sort" aria-hidden="true"></i>
                </span>
            </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu4" role="menu">
            <div class="dropdown-content">
                <a class="dropdown-item is-size-7" @click.prevent="sort('origem', 'Origem')">Origem</a>
                <a class="dropdown-item is-size-7" @click.prevent="sort('crm', 'CRM')">CRM</a>
                <a class="dropdown-item is-size-7" @click.prevent="sort('status', 'Status')">Status</a>
                <a class="dropdown-item is-size-7" @click.prevent="sort('dataHoraInclusao', 'Data inclusão')">Data inclusão</a>
                <a class="dropdown-item is-size-7" @click.prevent="sort('dataHoraPagamento', 'Data pagamento')">Data pagamento</a>
                <a class="dropdown-item is-size-7" @click.prevent="sort('dtGeracao', 'Data Geração')">Data Geração</a>
            </div>
            </div>
        </div>
        <div class="column is-8 is-pulled-left is-paddingless has-padding-top-5">
            <label class="is-pulled-left is-size-7 has-padding-left-30">{{ textoPareExibicao }}</label>
        </div>
    </div>
</template>

<script>
var _ = require('lodash');
export default {
    props: {
        dadosGridBoleto: Array,
    },
    data() {
        return {
            colunaSort: 'nome',
            colunaOrder: 'asc',
            textoPareExibicao: ''
        }
    },
    methods: {
        limparOrdenacao() {
            this.textoPareExibicao = ''
        },
        sort(coluna, label) {
            if (this.colunaSort == coluna) {
                this.colunaOrder = (this.colunaOrder == 'asc') ? 'desc' : 'asc';
            }
            else
            {               
                this.colunaSort = coluna;
                this.colunaOrder = 'asc';
            }
            this.textoPareExibicao = label
            this.$emit("gridOrdenada", _.orderBy(this.dadosGridBoleto, this.colunaSort, this.colunaOrder))
        }
    }
}
</script>

<style style="scss">
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>