import Vue from 'vue'
import Vuex from 'vuex'
import { boletoServico } from '../services/boleto.service'
import { autenticacaoServico } from '../services/autenticacao.service'
import TabelaParser from '@/helpers/tabelaParse'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    listaFiltrada: [],
    listaBoletosParse: '',
    token:'',
    dadosModal:{
      exibirModal: '',
      textoModal:''
    }
  },
  mutations: {
    preencherListaFiltrada(state,payload) {
      state.listaFiltrada = payload
    },
    listaBoletosParserRetorno(state,payload) {
      state.listaFiltrada = payload
      state.listaBoletosParse = payload
    },
    armazenarToken(state,payload) {
      state.token = payload
    },
    exibirOcultarModalLoading(state,payload) {
      state.dadosModal.exibirModal = payload.exibirModal
      state.dadosModal.textoModal = payload.textoModal
    },
    gravarValorTotal(state,payload) {
      state.valorTotalPago = payload.pago
      state.valorTotalTarifa = payload.tarifa
    }
  },
  actions: {
    retornarBoleto({commit, dispatch },payload) {
      boletoServico.retornaBoleto(commit, dispatch,payload)
    },
    autenticar({commit},payload) {
      autenticacaoServico.autenticar(commit,payload)
    },
    gerarToken({commit},payload) {
      autenticacaoServico.gerarToken(commit,payload)
    },
    listaBoletosParser({commit},payload) {
      let novaLista = []
      for (let index = 0; index < payload.length; index++) {
        novaLista.push(new TabelaParser().parseTo(payload[index]))
      }
      commit("listaBoletosParserRetorno", novaLista)
    }
  }
})
